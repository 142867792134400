import {useState, useEffect, useCallback} from 'react'
import {Button, Input, Select, DatePicker, TitlePortal} from '~/components/ui'
import {useRepository, useError, useToast} from '~/components/hooks'
import {useBreadcrumbs} from '~/context/breadcrumb'
import {groupBy} from '~/utils/Array'
import {Titular, Account} from "~/api/models";

interface ITitularAccounts {
  title: string
  items: Account[]
}

const baseState = {
  titular_id: '', account_id: '', amount: '',
  transaction: '', number: '', enterprise_id: '',
  concept: '', observation: '',
  realized: 'Yoleizy Henriquez', authorized: '', issued_at: ''
}

const CreatePaymentScreen = () => {
  const {setBreadcrumb} = useBreadcrumbs()
  const {setErrors, error} = useError()
  const toast = useToast()
  const [state, setState] = useState(baseState)
  const [loadings, setLoadings] = useState({ titulars: false, accounts: false, store: false })
  const [titulars, setTitulars] = useState<Titular[]>([])
  const [titular, setTitular] = useState<Titular | undefined>()
  const [accounts, setAccounts] = useState<Account[]>([])
  const [enterpriseAccounts, setEnterpriseAccounts] = useState<ITitularAccounts[]>([])
  const [titularAccounts, setTitularAccounts] = useState<ITitularAccounts[]>([])

  const {fetchTitulars, fetchAccounts, storePayment, paymentReportURL} = useRepository(({ titulars, accounts, payments, reports }) => ({
    fetchTitulars: titulars.index,
    fetchAccounts: accounts.index,
    storePayment: payments.create,
    paymentReportURL: reports.payment,
  }))

  const reducedAccounts = useCallback((predicate) => {
    return accounts.reduce((accumulator, account) => {
      if (!accumulator.some(iterated => iterated.title === account.type)) {
        accumulator.push({
          title: account.type,
          items: accounts.filter(iterated => iterated.type === account.type).filter(predicate)
        })
      }

      return accumulator
    }, [] as ITitularAccounts[])
      .filter(accumulator => accumulator.items.length > 0)
  }, [accounts])

  useEffect(()=>{
    let accounts = enterpriseAccounts.map(e =>e.items).flat()
    let filter= accounts.filter(account=> {
        return account.capital ?? 0 < parseFloat(state.amount)
    }).map(e=>e.id)

    if(!filter.includes(Number(state.enterprise_id)))    
      setState(prev => ({...prev, enterprise_id: ''}))
  }, [state.amount]) // eslint-disable-line

  useEffect(() => {
    setLoadings(prev => ({...prev, titulars: !prev.titulars}))
    fetchTitulars<Titular[]>({ limit: 0 })
      .then(setTitulars)
      .finally(() => setLoadings(prev => ({...prev, titulars: !prev.titulars})))

    setLoadings(prev => ({...prev, accounts: !prev.accounts}))
    fetchAccounts<Account[]>({ limit: 0, with: 'bank,titular' })
      .then(setAccounts)
      .finally(() => setLoadings(prev => ({...prev, accounts: !prev.accounts})))

    setBreadcrumb([
      { title: 'Órdenes personales', to: '/payments' },
      { title: 'Crear órden', to: '/payments/create' },
    ])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEnterpriseAccounts(reducedAccounts((account: Account) => account.titular?.scope === 'Empresa'))
  }, [accounts, reducedAccounts])

  useEffect(() => {

    if (state.titular_id) {
      setTitularAccounts(reducedAccounts((account: Account) => account.titular_id?.toString() === state.titular_id.toString()))
    }

    const found = titulars.find($0 => $0.id.toString() === state.titular_id)
    if (found) {
      setTitular(found)
    }
  }, [state.titular_id, reducedAccounts, titulars])

  const handleStore = () => {
    setLoadings(prev => ({...prev, store: !prev.store}))
    storePayment(state)
      .then(({ id }) => {
        toast.success({
            title: 'La orden ha sido generada.',
            message: () => (
              <p>
                Se ha creado una órden para el titular <strong>{titular?.name}</strong> <br/>
                <a rel="noreferrer" href={paymentReportURL(id)} target="_blank" className="text-primary inline-block mt-4">Presione aqui</a> para generar.
              </p>
            ),
            lifetime: -1,
        })
        setState(baseState)
      })
      .catch(setErrors())
      .finally(() => setLoadings(prev => ({...prev, store: !prev.store})))
  }

  return (
    <>
      <TitlePortal>
        Crear órden personal
      </TitlePortal>
      <div className="w-full p-2">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full xl:w-5/6 px-2 flex flex-wrap">
            <div className="w-full flex flex-wrap p-2 rounded-2xl">
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
                <Select
                  required
                  id="titular_id"
                  label="Titular"
                  error={error('titular_id')}
                  value={state.titular_id}
                  loading={loadings.titulars}
                  onChange={titular_id => setState(prev => ({...prev, titular_id: (titular_id as string)}))}
                >
                  <option value="">-- Titular --</option>
                  {Object.entries(groupBy(titulars, 'scope')).map(([attribute, value]) => (
                    <optgroup key={`${attribute}`} label={attribute}>
                      {value.map(($0) => (
                        <option title={$0.name} value={$0.id} key={`${attribute}_${$0.id}`}>{$0.name}</option>
                      ))}
                    </optgroup>
                  ))}
                  {titulars.sort((a, b) => a.name.localeCompare(b.name)).map($0 => <option key={$0.id} value={$0.id}>{$0.name}</option>)}
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
                <Select
                  required
                  id="account_id"
                  label="Cuenta (Opcional)"
                  disabled={!titular}
                  error={error('account_id')}
                  value={state.account_id}
                  loading={loadings.accounts}
                  onChange={account_id => setState(prev => ({...prev, account_id: account_id as string}))}>
                  <option value="">-- Cuenta --</option>
                  {titularAccounts
                    .map(($0, $1) => (
                      <optgroup key={$1} label={$0.title}>
                        {$0.items.map(($2, $3) => <option title={$2.number.toString()} value={$2.id} key={`${$1}_${$3}`}>{$2.bank?.name}</option>)}
                      </optgroup>
                    ))}
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2">
                <Input
                  required
                  id="amount"
                  label="Monto"
                  error={error('amount')}
                  value={state.amount}
                  onChange={amount => setState(prev => ({...prev, amount}))} />
              </div>

              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Select
                  required
                  id="transaction"
                  label="Forma de pago"
                  value={state.transaction}
                  error={error('transaction')}
                  onChange={transaction => {
                    setState(prev => ({...prev, transaction: transaction as string}))                    
                  }}>
                  <option value="">-- Forma de pago --</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Depósito">Depósito</option>
                  <option value="Transferencia">Transferencia</option>
                  <option value="Pago móvil">Pago móvil</option>
                </Select>
              </div>

              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Input
                  required
                  id="number"
                  error={error('number')}
                  label={`Número de ${state.transaction.toLowerCase() || 'transacción'}`}
                  value={state.number}
                  onChange={number => setState(prev => ({...prev, number}))} />
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Select
                  required
                  id="enterprise_id"
                  error={error('enterprise_id')}
                  label="Cuenta de la empresa"
                  value={state.enterprise_id}
                  loading={loadings.accounts}
                  onChange={enterprise_id => setState(prev => ({...prev, enterprise_id: enterprise_id as string}))}
                >
                  <option value="">-- Cuenta --</option>
                  {enterpriseAccounts
                    .map(($0, $1) => (
                      <optgroup key={$1} label={$0.title}>
                        {$0.items.map(($2, $3) => {                          
                          const capital: number = $2.capital ?? 0                          
                          const disabled = capital < parseFloat(state.amount)
                          
                          return (
                            <option
                              disabled={disabled}
                              title={`${$2.number}${disabled  ? ' (Fondo insuficiente)' : ` (Disponible ${$2.capital}Bs)`}`}
                              value={$2.id}
                              key={`${$1}_${$3}`}
                            >
                              {$2.bank?.name}
                            </option>
                          )
                        })}
                      </optgroup>
                    ))}
                </Select>
              </div>

              <div className="w-full lg:w-1/2 px-2 mt-4">
                <Input
                  id="concept"
                  error={error('concept')}
                  label="Concepto"
                  value={state.concept}
                  required
                  onChange={concept => setState(prev => ({...prev, concept}))}/>
              </div>
              <div className="w-full lg:w-1/2 px-2 mt-4">
                <Input
                  error={error('observation')}
                  id="observation"
                  label="Observación"
                  value={state.observation}
                  onChange={observation => setState(prev => ({...prev, observation}))}/>
              </div>

              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Select
                  id="realized"
                  error={error('realized')}
                  label="Realizado por"
                  value={state.realized}
                  onChange={realized => setState(prev => ({...prev, realized: realized as string}))}
                >
                  <option value="Yoleizy Henriquez">Yoleizy Henriquez</option>
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <Select
                  id="authorized"
                  label="Autorizado por"
                  error={error('authorized')}
                  value={state.authorized}
                  onChange={authorized => setState(prev => ({...prev, authorized: authorized as string}))}>
                  <option value="">-- Seleccione --</option>
                  <option value="Antonio Figueroa">Antonio Figueroa</option>
                  <option value="Glensimar García">Glensimar García</option>
                </Select>
              </div>
              <div className="w-full xl:w-1/3 lg:w-1/2 px-2 mt-4">
                <DatePicker
                  required
                  id="issued_at"
                  label="Fecha"
                  value={state.issued_at}
                  // error={error('issued_at')}
                  onChange={([issued_at]) => setState(prev => ({...prev, issued_at}))} />
              </div>
            </div>
          </div>

          <div className="flex w-full xl:w-1/6 flex-col space-y-2 px-2 mt-4 xl:mt-0">
            <Button variant="primary" disabled={loadings.store} onClick={handleStore}>{loadings.store ? 'Procesando...' : 'Guardar'}</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreatePaymentScreen
